import { conform, useForm } from "@conform-to/react";
import { getFieldsetConstraint, parse } from "@conform-to/zod";
import { json, redirect } from "@remix-run/node";
import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import {
  Form,
  useActionData,
  useLoaderData,
  useSearchParams,
} from "@remix-run/react";
import { z } from "zod";
import {
  requireAnonymous,
  sessionKey,
  signup,
} from "~/utils/auth-utils/auth.server";
import {
  NameSchema,
  PasswordSchema,
} from "~/utils/model-utils/user-validation";
import { verifySessionStorage } from "~/utils/verification.server";
import type { VerifyFunctionArgs } from "./verify";
import invariant from "tiny-invariant";
import { useIsPending } from "~/utils/misc";
import { CheckboxField, ErrorList } from "~/components/ui/Forms";
import { authSessionStorage } from "~/utils/model-utils/session.server";
import { AuthenticityTokenInput } from "remix-utils/csrf/react";
import { validateCSRF } from "~/utils/csrf.server";
import { Button } from "~/components/ui/Button";
import { Label } from "~/components/ui/Label";
import { Input } from "~/components/ui/Input";

const onboardingEmailSessionKey = "onboardingEmail";

const SignupFormSchema = z
  .object({
    name: NameSchema,
    password: PasswordSchema,
    confirmPassword: PasswordSchema,
    agreeToTermsOfServiceAndPrivacyPolicy: z.string({
      required_error:
        "You must agree to the terms of service and privacy policy",
    }),
    remember: z.string().optional(),
    redirectTo: z.string().optional(),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        path: ["confirmPassword"],
        code: "custom",
        message: "The passwords must match",
      });
    }
  });

async function requireOnboardingEmail(request: Request) {
  await requireAnonymous(request);
  const verifySession = await verifySessionStorage.getSession(
    request.headers.get("cookie"),
  );
  const email = verifySession.get(onboardingEmailSessionKey);
  if (typeof email !== "string" || !email) {
    throw redirect("/signup");
  }
  return email;
}
export async function loader({ request }: LoaderFunctionArgs) {
  const email = await requireOnboardingEmail(request);
  return json({ email });
}

export async function action({ request }: ActionFunctionArgs) {
  const email = await requireOnboardingEmail(request);
  const formData = await request.formData();
  // 🐨 validate the CSRF token
  await validateCSRF(formData, request.headers);

  const submission = await parse(formData, {
    schema: SignupFormSchema.superRefine(async (data, ctx) => {}).transform(
      async (data) => {
        const session = await signup({ ...data, email, request });
        return { ...data, session };
      },
    ),
    async: true,
  });

  if (submission.intent !== "submit") {
    return json({ status: "idle", submission } as const);
  }
  if (!submission.value?.session) {
    return json({ status: "error", submission } as const, { status: 400 });
  }

  const { session, remember, redirectTo } = submission.value;

  const authSession = await authSessionStorage.getSession(
    request.headers.get("cookie"),
  );
  authSession.set(sessionKey, session.id);
  const verifySession = await verifySessionStorage.getSession(
    request.headers.get("cookie"),
  );
  const headers = new Headers();
  headers.append(
    "set-cookie",
    await authSessionStorage.commitSession(authSession, {
      expires: remember ? session.expirationDate : undefined,
    }),
  );
  headers.append(
    "set-cookie",
    await verifySessionStorage.destroySession(verifySession),
  );

  // Direct the user to /getting-started after initial signup for a smoother onboarding process
  return redirect(redirectTo ? redirectTo : "/getting-started", { headers });
}

export async function handleVerification({
  request,
  submission,
}: VerifyFunctionArgs) {
  invariant(submission.value, "submission.value should be defined by now");
  const verifySession = await verifySessionStorage.getSession(
    request.headers.get("cookie"),
  );
  verifySession.set(onboardingEmailSessionKey, submission.value.target);
  return redirect("/onboarding", {
    headers: {
      "set-cookie": await verifySessionStorage.commitSession(verifySession),
    },
  });
}

export const meta: MetaFunction = () => {
  return [{ title: "Setup Ranger Budget Account" }];
};

export default function SignupRoute() {
  const data = useLoaderData<typeof loader>();
  const actionData = useActionData<typeof action>();
  const isPending = useIsPending();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");

  const [form, fields] = useForm({
    id: "signup-form",
    constraint: getFieldsetConstraint(SignupFormSchema),
    defaultValue: { redirectTo },
    lastSubmission: actionData?.submission,
    onValidate({ formData }) {
      return parse(formData, { schema: SignupFormSchema });
    },
    shouldRevalidate: "onBlur",
  });

  return (
    <div className="flex min-h-full flex-col justify-center px-8 pb-32 pt-20">
      <div className="mx-auto flex w-full max-w-md flex-col gap-8 lg:gap-12">
        <div className="flex flex-col gap-3 text-center">
          <p className="break-words text-lg font-semibold lg:text-2xl">
            Welcome aboard <br /> {data.email}
          </p>
          <p className="text-base text-muted-foreground">
            Please enter your details.
          </p>
        </div>

        <div className="mx-auto w-full max-w-md">
          <Form
            method="POST"
            className="mx-auto flex max-w-sm flex-col gap-4 md:gap-6"
            {...form.props}
          >
            <AuthenticityTokenInput />
            <div className="space-y-1.5 md:space-y-2">
              <Label>Name</Label>
              <Input type="name" {...conform.input(fields.name)} />
              {fields.name.errors && (
                <div id={`${fields.name.id}-error`}>
                  <ErrorList errors={fields.name.errors} />
                </div>
              )}
            </div>
            <div className="space-y-1.5 md:space-y-2">
              <Label>Password</Label>
              <Input type="password" {...conform.input(fields.password)} />
              {fields.password.errors && (
                <div id={`${fields.password.id}-error`}>
                  <ErrorList errors={fields.password.errors} />
                </div>
              )}
            </div>
            <div className="space-y-1.5 md:space-y-2">
              <Label>Confirm Password</Label>
              <Input
                type="password"
                {...conform.input(fields.confirmPassword)}
              />
              {fields.confirmPassword.errors && (
                <div id={`${fields.confirmPassword.id}-error`}>
                  <ErrorList errors={fields.confirmPassword.errors} />
                </div>
              )}
            </div>
            <div className="flex flex-col gap-4 py-4">
              <CheckboxField
                className="text-sm lg:text-base"
                labelProps={{
                  htmlFor: fields.agreeToTermsOfServiceAndPrivacyPolicy.id,
                  children: (
                    <span>
                      Do you agree to our{" "}
                      <Button
                        className="h-auto p-0 text-sm lg:text-base"
                        variant="link"
                        asChild
                      >
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href="https://rangerbudget.com/terms-of-use"
                        >
                          Terms of Use
                        </a>
                      </Button>{" "}
                      and{" "}
                      <Button
                        className="h-auto p-0 text-sm lg:text-base"
                        variant="link"
                        asChild
                      >
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href="https://rangerbudget.com/privacy-policy"
                        >
                          Privacy Policy
                        </a>
                      </Button>
                      ?
                    </span>
                  ),
                }}
                buttonProps={conform.input(
                  fields.agreeToTermsOfServiceAndPrivacyPolicy,
                  { type: "checkbox" },
                )}
                errors={fields.agreeToTermsOfServiceAndPrivacyPolicy.errors}
              />
              <CheckboxField
                className="text-sm lg:text-base"
                labelProps={{
                  htmlFor: fields.remember.id,
                  children: "Remember me",
                }}
                buttonProps={conform.input(fields.remember, {
                  type: "checkbox",
                })}
                errors={fields.remember.errors}
              />
            </div>
            <input {...conform.input(fields.redirectTo, { type: "hidden" })} />
            <ErrorList errors={form.errors} id={form.errorId} />

            <div className="flex items-center justify-between gap-6">
              <Button className="w-full" type="submit" disabled={isPending}>
                Create an account
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}
